.colorPickerContainer {
    opacity: 0.9;
    position: absolute;
    margin-left: 25vw;
    width: 50vw;
    max-width: 100vw;
    background-color:grey;
    border:2px black solid;
    display: flex;
    flex-wrap: wrap;
}

.colorPickerContainer > button {
    width: 10vw;
    height: 10vw;
    padding: 0;
    border:none;
}

.colorPicker {
    width: 95vw;
    display: flex;
    flex-wrap: wrap;
    margin: 10px auto 0 auto;
    box-shadow: 0 0 4px 4px #5b5b5b;
    border-radius: 55px;
    overflow: hidden;
    padding:0;
}

.colorPicker > button {
    width: 50%;
    height: 51px;
    margin: 0;
    padding: 0;
    border:none;
}
