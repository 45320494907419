.menu.buttonRow {
    color: white;
    width: 100%;
    margin: 0;
    background-color: #eff0ff;
    height: 100px;
}

.menu.buttonRow > button {
    border-radius: 0;
    color: #748ad8;
    width: 95%;
    box-shadow: none;
    font-size: 28px;
    text-shadow: 1px 1px darkblue;
    margin: 5px;
    background-color: #dbe3ff;
}

.table {
}

.numberTableRow2 {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.numberTableRow {
    display: inline-flex;
    width: 47vw;
    height: 18vw;
    max-height: 115px;
    justify-content: center;

    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.numberTableRow:nth-child(2n) {
    align-content: flex-start;
    padding-left: 15px;
}

.numberTableRow:nth-child(2n-1) {
    align-content: flex-end;
    padding-right: 15px;
}

.numberTableRow:nth-child(13) {
    margin-bottom: 10px;
}

.numberBox2 {
    width: 9vw;
    height: 9vw;
    max-width: 55px;
    max-height: 55px;
    border: 1px black solid;
    box-shadow: 0 0 3px 1px black;
    background-color: transparent;
    border-radius: 8vw;
    margin: 5px 1px 5px 1px;
    font-size: inherit;
    padding: 0;
    text-shadow: 1px 1px #696969;
}

.numberBox {
    box-shadow: 0 0 2px 1px black;
    margin:1px;
    background-color: transparent;
    height: 6vw;
    max-height: 55px;
    width: 6vw;
    max-width: 55px;
    border-radius: 8vw;
    font-size: inherit;
    padding: 0;
    text-shadow: 1px 1px #696969;
}

.chosenColor {
    width: 50px;
    height: 50px;
    border-radius: 30px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

.buttonRow, .buttonRowBottom {
    display: flex;
    width: 90vw;
    margin: 0 auto 0 auto;
}

.buttonRow > button, .buttonRowBottom > button {
    width: 50%;
    background-color: transparent;
    border-radius: 3px;
    padding: 5px;
    border: none;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.29);
    margin: 5px;
}

.buttonRowBottom > button {
    margin: 0 auto 0 auto;
}

@media screen and (max-width: 4500px) {
    .numberTableRow, .numberTableRow2 {
        font-size: 18px;
    }
}

@media screen and (max-width: 400px) {
    .numberTableRow, .numberTableRow2 {
        font-size: 12px;
    }
}

@media screen and (max-width: 300px) {
    .numberTableRow, .numberTableRow2 {
        font-size: 8px;
    }
}
